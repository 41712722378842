import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useApp } from "../AppProvider";
import { useStateIfMounted } from "use-state-if-mounted";

export const useSubmitData = (
  selectedWell,
  setSelectedWell,
  editorState,
  endpoint,
  ndxName,
  refetch,
  refetchWells = () => {}
) => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const [isFormSubmitting, setIsFormSubmitting] = useStateIfMounted(false);

  const handleSubmit = () => {
    if (editorState[ndxName]) {
      handleUpdate().then();
    } else {
      handleAdd().then();
    }
  };

  const handleAdd = () => {
    return (async () => {
      try {
        setIsFormSubmitting(true);

        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`,
          editorState,
          { headers }
        );
        setSelectedWell(data?.well_ndx);
        setIsFormSubmitting(false);
        refetchWells();
        doToast("success", "New entry was saved to the database");
        await refetch();
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdate = () => {
    return (async () => {
      try {
        if (editorState) {
          setIsFormSubmitting(true);

          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}/${editorState[ndxName]}`,
            editorState,
            { headers }
          );
          setIsFormSubmitting(false);
          refetchWells();
          doToast("success", "New data was updated to the database");
          await refetch();
        } else {
          setIsFormSubmitting(false);
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        setIsFormSubmitting(false);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  return {
    handleSubmit,
    isFormSubmitting,
  };
};
