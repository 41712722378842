import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import useFetchData from "../../../../hooks/useFetchData";
import { useMemo } from "react";

export const useRatingCurves = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRatingCurve, setSelectedRatingCurve] = useStateIfMounted(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["flow-from-stage"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/flow-from-stage`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [measurementLocationsLookup] = useFetchData(
    "ui-rating-curve-measurement-locations",
    [],
    true
  );
  const measurementLocationsLookupForTable = useMemo(() => {
    let converted = {};
    if (measurementLocationsLookup?.length > 0) {
      measurementLocationsLookup.forEach((item) => {
        converted[item.measurement_ndx] = item.measurement_name;
      });
    }
    return converted;
  }, [measurementLocationsLookup]);

  const [displaysLookup] = useFetchData("ui-rating-curve-displays", [], true);
  const displaysLookupForTable = useMemo(() => {
    let converted = {};
    if (displaysLookup?.length > 0) {
      displaysLookup.forEach((item) => {
        converted[item.ffs_ndx] = item.rating_curve_display;
      });
    }
    return converted;
  }, [displaysLookup]);

  const RATING_CURVES_TABLE_COLUMNS = [
    {
      title: "Rating Curve Name",
      field: "ffs_desc",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "Location",
      field: "measurement_ndx",
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
      lookup: measurementLocationsLookupForTable,
    },

    {
      title: "Effective Date",
      field: "ffs_effective_ts",
      type: "date",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Low Stage, Ft",
      field: "ffs_low_stage",
      cellStyle: {
        width: 100,
        minWidth: 100,
      },
    },
    {
      title: "High Stage, Ft",
      field: "ffs_high_stage",
      cellStyle: {
        width: 100,
        minWidth: 100,
      },
    },
    {
      title: "Formula",
      field: "ffs_ndx",
      cellStyle: {
        width: 600,
        minWidth: 600,
      },
      lookup: displaysLookupForTable,
    },
  ];

  return {
    selectedRatingCurve,
    setSelectedRatingCurve,
    data,
    isFetching,
    error,
    refetch,
    RATING_CURVES_TABLE_COLUMNS,
  };
};
