import React, { useCallback } from "react";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Link,
} from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";

import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import axios from "axios";
import { NavLink } from "react-router-dom";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const SitesSummaryTemperatureTable = () => {
  const { data, isFetching, error } = useQuery(
    ["/api/ui-temperature-last-report"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-temperature-last-report`
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const tabColumns = [
    {
      title: "Location",
      field: "location_name",
    },
    {
      title: "Parameter",
      field: "parameter",
    },
    {
      title: "Last Report",
      field: "last_report",
    },
    {
      title: "Last Value",
      field: "last_value",
    },
    {
      title: "Units",
      field: "unit_desc",
    },
  ];

  const getRowStyle = useCallback((rowData) => {
    return {
      backgroundColor: rowData.stale_data ? "lightGrey" : "inherit",
    };
  }, []);

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet title="Temperature" />
      <Typography variant="h3" gutterBottom display="inline">
        Last Reported Water Temperature
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Last Reported Water Temperature</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Panel>
        <TableWrapper>
          {data ? (
            <Table
              label="Last Reported Water Temperature"
              isLoading={isFetching}
              columns={tabColumns}
              data={data}
              height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
              pageSize={60}
              options={{
                filtering: false,
                rowStyle: getRowStyle,
                search: false,
              }}
            />
          ) : (
            <Loader />
          )}
        </TableWrapper>
      </Panel>
    </>
  );
};

export default SitesSummaryTemperatureTable;
