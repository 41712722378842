import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 64px - 48px - 48px - 64px);
  position: relative;
`;

const PublicFiles = () => {
  return (
    <>
      <Helmet title="Public Files" />

      <Container>
        <iframe
          src="https://drive.google.com/embeddedfolderview?id=1KvnPHMws8XaB_eZwIoe7I6JOruE-9YNx#list"
          width="100%"
          height="100%"
          frameBorder="0"
          title="Public Files"
        />
      </Container>
    </>
  );
};

export default PublicFiles;
