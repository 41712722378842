import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { useAuth0 } from "@auth0/auth0-react";
import Link from "@material-ui/core/Link";
import { customSecondary } from "../../../theme/variants";
import Card from "@material-ui/core/Card";
import DashboardMap from "./DashboardMap";

const Typography = styled(MuiTypography)(spacing);

const Grid = styled(MuiGrid)(spacing);

const MapContainer = styled.div`
  height: 500px;
  width: 100%;
`;

const Hero = styled.div`
  background: url("/static/img/townofeagle-hero.jpg") center center / cover
    no-repeat;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Recap = styled.section`
  border-top: 5px solid ${() => customSecondary[500]};
  margin-bottom: 24px;
  margin-top: 24px;
`;

const Description = styled.div`
  background-color: ${({ color = customSecondary[500] }) => color};
  color: white;
  padding: 30px 60px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Welcome = styled.div`
  height: 180px;
  background-color: rgba(47, 44, 116, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 26px;
  border-radius: 50%;
  position: absolute;
`;

function Default() {
  const { user } = useAuth0();

  return (
    <React.Fragment>
      <Helmet title="Town of Eagle Streamflow Dashboard" />
      <Hero>
        <Welcome>
          <Typography variant="h3" gutterBottom style={{ color: "azure" }}>
            Welcome to the
          </Typography>
          <Typography variant="h2" gutterBottom style={{ color: "azure" }}>
            Town of Eagle Streamflow Dashboard
          </Typography>

          <Typography variant="subtitle1" style={{ color: "azure" }}>
            {user?.name}
          </Typography>
        </Welcome>
      </Hero>

      <Grid item xs={12} style={{ marginTop: "24px" }}>
        <Card>
          <CardContent>
            <MapContainer>
              <DashboardMap />
            </MapContainer>
          </CardContent>
        </Card>
      </Grid>

      <Recap>
        <Grid container>
          <Grid item xs={12} lg={4} style={{ backgroundColor: "white" }}>
            <Link
              href="https://rivid.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={"/static/img/townofeagle-logo-large-square.png"}
                style={{
                  margin: "auto",
                  display: "block",
                  maxWidth: "100%",
                  // objectFit: "cover",
                  objectFit: "contain",
                  height: "100%",
                }}
                alt="Logo"
              />
            </Link>
          </Grid>

          <Grid item xs={12} lg={8}>
            <Description color="#424242">
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "25px 25px 0 25px",
                  lineHeight: "1.8",
                  fontSize: "1.25rem",
                }}
              >
                This tool provides access to Brush Creek streamflow monitoring
                data managed by the Town of Eagle.
              </Typography>
              <Typography
                variant="body2"
                component="p"
                style={{
                  padding: "25px",
                  lineHeight: "1.8",
                  fontSize: "1.15rem",
                  fontStyle: "italic",
                }}
              >
                Flows are calculated from measured stream depths based on stage
                to flow relationships. These relationships have been developed
                for flows up to approximately 43 CFS at the Ouzel Lane and Love
                & White sites, and up to approximately 27 CFS at the Upper
                Diversion site.. The accuracy of the calculated flows above
                these levels has not been verified.
              </Typography>
            </Description>
          </Grid>
        </Grid>
      </Recap>
    </React.Fragment>
  );
}

export default Default;
