import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  Paper,
  TextField,
  Typography as MuiTypography,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

import Loader from "../../../../components/Loader";
import { useSubmitData } from "../../../../hooks/useSubmitData";
import { useRatingCurveInfo } from "./useRatingCurveInfo";
import { getButtonText, getButtonIcon } from "./utils";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import Panel from "../../../../components/panels/Panel";
import { isDisabled, REQUIRED_STATE_VALUES } from "./RatingCurvesConstants";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

function RatingCurveInfo({
  selectedRatingCurve,
  setSelectedRatingCurve,
  refetchRatingCurves = () => {},
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
  } = useRatingCurveInfo(selectedRatingCurve);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedRatingCurve,
    setSelectedRatingCurve,
    state,
    "flow-from-stage",
    "ffs_ndx",
    refetch,
    refetchRatingCurves
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.ffs_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion
            defaultExpanded
            style={{
              display:
                isLoading || (isEditMode && !state?.ffs_ndx) ? "none" : "block",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box style={{ width: "100%" }}>
                <Typography variant="h3">Rating Curve Information</Typography>
              </Box>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <Grid container spacing={5}>
                  <Typography
                    variant="h5"
                    mt={3}
                    ml={4}
                    style={{ width: "100%" }}
                  >
                    Rating Curve Details
                    <Divider my={1} />
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Rating Curves Name"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleUpdateState("ffs_desc", e.target.value)
                      }
                      value={state.ffs_desc ?? ""}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      {!lookups?.measurementLocations.length > 0 ? (
                        <Loader />
                      ) : (
                        <>
                          <InputLabel id="measurementLocation">
                            Measurement Location
                          </InputLabel>
                          <Select
                            labelId="measurement-location"
                            id="measurement-location"
                            label="Measurement Location"
                            name="measurement_ndx"
                            value={state.measurement_ndx ?? ""}
                            onChange={(e) => {
                              handleUpdateState(
                                "measurement_ndx",
                                e.target.value
                              );
                            }}
                          >
                            {lookups.measurementLocations.map((option) => (
                              <MenuItem
                                key={option.measurement_ndx}
                                value={option.measurement_ndx}
                              >
                                {option.measurement_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={3}
                    style={{
                      position: "relative",
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <FormControl style={{ width: "100%" }}>
                        <KeyboardDatePicker
                          format="LLLL do, yyyy"
                          inputVariant="outlined"
                          autoOk
                          id="effective-date"
                          label="Effective (Start) Date"
                          value={state?.ffs_effective_ts}
                          onChange={(date) => {
                            handleUpdateState("ffs_effective_ts", date);
                          }}
                          InputAdornmentProps={{
                            "aria-label": "effective start date",
                          }}
                        />
                      </FormControl>
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={3}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      type="number"
                      variant="outlined"
                      label="Low Stage, Ft"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          handleUpdateState("ffs_low_stage", null);
                        } else {
                          handleUpdateState("ffs_low_stage", +e.target.value);
                        }
                      }}
                      value={state?.ffs_low_stage ?? ""}
                      InputProps={{
                        inputProps: {
                          onKeyPress: (e) => {
                            if (e.key === "e") {
                              e.preventDefault();
                            }
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={3}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      type="number"
                      variant="outlined"
                      label="High Stage, Ft"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          handleUpdateState("ffs_high_stage", null);
                        } else {
                          handleUpdateState("ffs_high_stage", +e.target.value);
                        }
                      }}
                      value={state?.ffs_high_stage ?? ""}
                      InputProps={{
                        inputProps: {
                          onKeyPress: (e) => {
                            if (e.key === "e") {
                              e.preventDefault();
                            }
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={3}
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      {!lookups?.types.length > 0 ? (
                        <Loader />
                      ) : (
                        <>
                          <InputLabel id="rating-curve-type">
                            Formula Type
                          </InputLabel>
                          <Select
                            labelId="rating-curve-type"
                            id="rating-curve-type"
                            label="Formula Type"
                            name="ffs_calc_type_ndx"
                            value={state.ffs_calc_type_ndx ?? ""}
                            onChange={(e) => {
                              handleUpdateState(
                                "ffs_calc_type_ndx",
                                e.target.value
                              );
                            }}
                          >
                            {lookups.types.map((option) => (
                              <MenuItem
                                key={option.ffs_calc_type_ndx}
                                value={option.ffs_calc_type_ndx}
                              >
                                {option.ffs_calc_type_desc}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Notes"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleUpdateState("notes", e.target.value)
                      }
                      value={state?.notes ?? ""}
                    />
                  </Grid>

                  <Typography
                    variant="h5"
                    mt={3}
                    ml={4}
                    style={{ width: "100%" }}
                  >
                    Formula Example
                    <Divider my={1} />
                  </Typography>

                  <Grid
                    item
                    xs={12}
                    style={{
                      position: "relative",
                    }}
                  >
                    <Typography variant="h2" align="center" color="secondary">
                      {lookups?.types?.find(
                        (item) =>
                          item.ffs_calc_type_ndx === state.ffs_calc_type_ndx
                      )?.rating_curve_example || ""}
                    </Typography>
                  </Grid>

                  <Typography
                    variant="h5"
                    mt={3}
                    ml={4}
                    style={{ width: "100%" }}
                  >
                    Shift and Constants
                    <Divider my={1} />
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={3}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      type="number"
                      variant="outlined"
                      label="Stage Shift"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          handleUpdateState("ffs_shift", null);
                        } else {
                          handleUpdateState("ffs_shift", +e.target.value);
                        }
                      }}
                      value={state?.ffs_shift ?? ""}
                      InputProps={{
                        inputProps: {
                          onKeyPress: (e) => {
                            if (e.key === "e") {
                              e.preventDefault();
                            }
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={3}
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      type="number"
                      variant="outlined"
                      label="Constant 'C'"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          handleUpdateState("ffs_c", null);
                        } else {
                          handleUpdateState("ffs_c", +e.target.value);
                        }
                      }}
                      value={state?.ffs_c ?? ""}
                      InputProps={{
                        inputProps: {
                          onKeyPress: (e) => {
                            if (e.key === "e") {
                              e.preventDefault();
                            }
                          },
                        },
                      }}
                    />
                  </Grid>
                  {[3].includes(state?.ffs_calc_type_ndx) && (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      xl={2}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="Constant 'u'"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            handleUpdateState("ffs_u", null);
                          } else {
                            handleUpdateState("ffs_u", +e.target.value);
                          }
                        }}
                        value={state?.ffs_u ?? ""}
                        InputProps={{
                          inputProps: {
                            onKeyPress: (e) => {
                              if (e.key === "e") {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>
                  )}
                  {[2].includes(state?.ffs_calc_type_ndx) && (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      xl={2}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="Constant 'D'"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            handleUpdateState("ffs_d", null);
                          } else {
                            handleUpdateState("ffs_d", +e.target.value);
                          }
                        }}
                        value={state?.ffs_d ?? ""}
                        InputProps={{
                          inputProps: {
                            onKeyPress: (e) => {
                              if (e.key === "e") {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>
                  )}
                  {[1, 2].includes(state?.ffs_calc_type_ndx) && (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      xl={2}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="Constant 'b'"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            handleUpdateState("ffs_b", null);
                          } else {
                            handleUpdateState("ffs_b", +e.target.value);
                          }
                        }}
                        value={state?.ffs_b ?? ""}
                        InputProps={{
                          inputProps: {
                            onKeyPress: (e) => {
                              if (e.key === "e") {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>
                  )}

                  <Typography
                    variant="h5"
                    mt={3}
                    ml={4}
                    style={{ width: "100%" }}
                  >
                    Formula Display
                    <Divider my={1} />
                  </Typography>

                  <Grid
                    item
                    xs={12}
                    style={{
                      position: "relative",
                    }}
                  >
                    <Typography variant="h2" align="center" color="secondary">
                      {state?.ffs_calc_type_ndx === 1
                        ? `flow = ${state?.ffs_c} * ( stage + ${state?.ffs_shift} ) + ${state?.ffs_b}`
                        : state?.ffs_calc_type_ndx === 2
                        ? `flow = ${state?.ffs_c} * ( stage + ${state?.ffs_shift} ) ^ 2 + ${state?.ffs_d} * ( x + ${state?.ffs_shift} ) + ${state?.ffs_b}`
                        : state?.ffs_calc_type_ndx === 3
                        ? `flow = ${state?.ffs_c} * ( stage + ${state?.ffs_shift} ) ^ ${state?.ffs_u}`
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Panel>
            <Divider />
            <AccordionActions>
              <Button size="small" onClick={handleResetState}>
                Reset
              </Button>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  REQUIRED_STATE_VALUES.some((value) =>
                    isDisabled(state[value])
                  ) ||
                  (state.ffs_calc_type_ndx === 1 && isDisabled(state.ffs_b)) || // if calc type is 3, then u is required
                  (state.ffs_calc_type_ndx === 2 &&
                    (isDisabled(state.ffs_d) || isDisabled(state.ffs_b))) || // if calc type is 2, then d and b are required
                  (state.ffs_calc_type_ndx === 3 && isDisabled(state.ffs_u)) || // if calc type is 3, then u is required
                  !isDirty ||
                  isFormSubmitting
                }
                startIcon={getButtonIcon(isFormSubmitting)}
                style={{ width: "100px" }}
              >
                {getButtonText(isFormSubmitting, selectedRatingCurve === 0)}
              </Button>
            </AccordionActions>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
}

export default RatingCurveInfo;
