export const DEFAULT_RATING_CURVE_STATE = {
  ffs_ndx: null,
  ffs_desc: null,
  measurement_ndx: null,
  ffs_effective_ts: null,
  ffs_low_stage: null,
  ffs_high_stage: null,
  ffs_calc_type_ndx: null,
  notes: null,
  ffs_shift: 0,
  ffs_c: null,
  ffs_u: null,
  ffs_d: null,
  ffs_b: null,
  formula_example: null,
};

export const REQUIRED_STATE_VALUES = [
  "ffs_desc",
  "measurement_ndx",
  "ffs_effective_ts",
  "ffs_low_stage",
  "ffs_high_stage",
  "ffs_calc_type_ndx",
  "ffs_shift",
  "ffs_c",
];

export function isDisabled(value) {
  return value === null || value === "" || value === undefined;
}
