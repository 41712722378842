import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Link,
  Paper,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import MaterialTable from "material-table";
import CopyIcon from "@material-ui/icons/FileCopy";
import { ExpandMore } from "@material-ui/icons";

import { NavLink } from "react-router-dom";
import { useApp } from "../../../../AppProvider";

import { copyToClipboard, dateFormatter } from "../../../../utils";
import RatingCurveInfo from "./RatingCurveInfo";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useRatingCurves } from "./useRatingCurves";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;
function RatingCurves() {
  const {
    selectedRatingCurve,
    setSelectedRatingCurve,
    data,
    isFetching,
    error,
    refetch,
    RATING_CURVES_TABLE_COLUMNS,
  } = useRatingCurves();
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/flow-from-stage/${oldData.ffs_ndx}`,
            { headers }
          );
          doToast("success", "This entry was deleted from the database");
          if (selectedRatingCurve === oldData.ffs_ndx) {
            setSelectedRatingCurve(null);
          }

          await refetch();
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <React.Fragment>
      <Helmet title="Rating Curves" />
      <Typography variant="h3" gutterBottom display="inline">
        Rating Curves
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Rating Curves</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3">Rating Curves</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TableWrapper>
              <MaterialTable
                id="Rating Curves"
                title={`Rating Curves ${dateFormatter(
                  new Date(),
                  "MM/DD/YYYY, h:mm A"
                )}`}
                loading={isFetching || !data}
                columns={RATING_CURVES_TABLE_COLUMNS}
                data={data}
                localization={{
                  toolbar: { searchPlaceholder: "Rating Curves Selection" },
                }}
                components={{
                  Container: (props) => <div {...props} />,
                }}
                editable={{
                  onRowDelete: handleDelete,
                }}
                actions={[
                  {
                    icon: CopyIcon,
                    tooltip: "Copy Data",
                    isFreeAction: true,
                    onClick: () => {
                      try {
                        copyToClipboard(data, RATING_CURVES_TABLE_COLUMNS, () =>
                          doToast(
                            "success",
                            "Data was copied to your clipboard."
                          )
                        );
                      } catch (error) {
                        const message =
                          error?.message ?? "Something went wrong";
                        doToast("error", message);
                      }
                    },
                  },
                  () => ({
                    icon: "edit",
                    tooltip: "Edit",
                    onClick: (event, rowData) => {
                      setSelectedRatingCurve(rowData?.ffs_ndx);
                    },
                  }),
                  {
                    icon: "add_box",
                    tooltip: "Add",
                    isFreeAction: true,
                    onClick: () => {
                      setSelectedRatingCurve(0);
                    },
                  },
                ]}
                options={{
                  filtering: false,
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  columnsButton: true,
                  exportButton: true,
                  exportAllData: true,
                  addRowPosition: "first",
                  pageSize: 10,
                  pageSizeOptions: [10, 30, 50, 100, 200],
                  padding: "dense",
                  searchFieldAlignment: "left",
                  maxBodyHeight:
                    "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px - 100px)",
                }}
              />
            </TableWrapper>
          </AccordionDetails>
        </Accordion>

        <Box
          style={{
            display:
              selectedRatingCurve || selectedRatingCurve === 0
                ? "block"
                : "none",
          }}
        >
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedRatingCurve === 0
                  ? "Creating New Rating Curve"
                  : "Editing Existing Rating Curve"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedRatingCurve(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
          <RatingCurveInfo
            selectedRatingCurve={selectedRatingCurve}
            setSelectedRatingCurve={setSelectedRatingCurve}
            refetchRatingCurves={refetch}
          />
          <Paper style={{ margin: "16px 0" }}>
            <Box
              p={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {selectedRatingCurve === 0
                  ? "Creating New Rating Curve"
                  : "Editing Existing Rating Curve"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSelectedRatingCurve(null)}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default RatingCurves;
