import React from "react";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Link,
} from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
// import Link from "@material-ui/core/Link";
// import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import { dateFormatter } from "../../../utils";
import axios from "axios";
import { NavLink } from "react-router-dom";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const SitesSummaryTable = () => {
  const { data, isFetching, error } = useQuery(
    ["/api/site-param-summary-table"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/site-param-summary-table`
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "Location",
      field: "location_name",
    },
    {
      title: "Parameter",
      field: "parameter",
    },
    {
      title: "Last Report",
      field: "last_collected ",
      render: (rowData) => {
        return dateFormatter(rowData.last_collected, "MM/DD/YYYY");
      },
    },
    {
      title: "Last Value",
      field: "last_value",
    },
    {
      title: "Units",
      field: "unit_desc",
    },
    {
      title: "First Collected",
      field: "first_report",
      render: (rowData) => {
        return dateFormatter(rowData.first_report, "MM/DD/YYYY");
      },
    },
    {
      title: "Collected Records",
      field: "recordcount",
    },
  ];

  return (
    <>
      <Helmet title="Site Summary" />
      <Typography variant="h3" gutterBottom display="inline">
        Site Summary Table
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Site Summary</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Panel>
        <TableWrapper>
          {data ? (
            <Table
              label="Site Summary"
              isLoading={isFetching}
              columns={tabColumns}
              data={data}
              height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
              pageSize={60}
              options={{ filtering: true }}
            />
          ) : (
            <Loader />
          )}
        </TableWrapper>
      </Panel>
    </>
  );
};

export default SitesSummaryTable;
