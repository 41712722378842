/* eslint-disable import/first */
import React from "react";
import { ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  Archive,
  FileText,
  Folder,
  Home,
  Monitor,
  Users,
  Briefcase,
  PenTool,
  Droplet,
  Target,
  Thermometer,
} from "react-feather";

import AuthGuard from "../components/AuthGuard";
import AdminGuard from "../components/AdminGuard";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";

import Blank from "../pages/pages/Blank";
import Landing from "../pages/presentation/Landing";
import Default from "../pages/dashboards/Default";

// TODO MAYBE LAZY IMPORT
import UserVisibilityFilter from "../components/UserVisibilityFilter";
import TimeseriesTemperature from "../pages/dataVisualization/timeSeries/TimeseriesTemperature";
import TimeseriesFlow from "../pages/dataVisualization/timeSeries/TimeseriesFlow";
import SitesSummaryTable from "../pages/dataVisualization/reports/SitesSummaryTable";
import DeveloperVisibilityFilter from "../components/DeveloperVisibilityFilter";
import DeveloperGuard from "../components/DeveloperGuard";
import PublicFiles from "../pages/publicFiles/PublicFiles";
import UserGuard from "../components/UserGuard";
import AuthVisibilityFilter from "../components/AuthVisibilityFilter";
import RatingCurves from "../pages/dataManagement/wells/RatingCurves/RatingCurves";
import SitesSummaryTemperatureTable from "../pages/dataVisualization/reports/SitesSummaryTemperatureTable";
const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const mainRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  icon: <Home />,
  component: Default,
  children: null,
  containsHome: true,
  guard: AuthGuard,
  visibilityFilter: AuthVisibilityFilter,
};

//DATA VISUALIZATION
//sub time series section
// const timeSeriesRoutes = {
//   header: "Data Visualization",
//   id: "Time Series",
//   icon: <Timeline />,
//   children: [
//     {
//       path: "/data-visualization/time-series/streamflow",
//       name: "Streamflow",
//       component: TimeseriesFlow,
//       guard: AdminGuard,
//       visibilityFilter: AdminVisibilityFilter,
//     },
//     {
//       path: "/data-visualization/time-series/flow-vs-targets",
//       name: "Flow vs Targets",
//       component: Blank,
//       guard: AdminGuard,
//       visibilityFilter: AdminVisibilityFilter,
//     },
//     {
//       path: "/data-visualization/time-series/temperature",
//       name: "Temperature",
//       component: TimeseriesTemperature,
//       guard: AdminGuard,
//       visibilityFilter: AdminVisibilityFilter,
//     },
//   ],
//   guard: AdminGuard,
//   visibilityFilter: AdminVisibilityFilter,
// };

const flowRoute = {
  header: "Data Visualization",
  id: "Streamflow",
  icon: <Droplet />,
  path: "/data-visualization/time-series/streamflow",
  name: "Site Summary",
  component: TimeseriesFlow,
  visibilityFilter: AuthVisibilityFilter,
  guard: AuthGuard,
};

const temperatureRoute = {
  id: "Temperature",
  icon: <Thermometer />,
  path: "/data-visualization/time-series/temperature",
  name: "Temperature",
  component: TimeseriesTemperature,
  guard: DeveloperGuard,
  visibilityFilter: DeveloperVisibilityFilter,
};

const flowVsTargetsRoute = {
  id: "Flow vs Targets",
  icon: <Target />,
  path: "/data-visualization/time-series/flow-vs-targets",
  name: "Flow vs Targets",
  component: Blank,
  guard: DeveloperGuard,
  visibilityFilter: DeveloperVisibilityFilter,
};

// sub reports section
const reportsRoutes = {
  id: "Reports",
  icon: <FileText />,
  children: [
    {
      path: "/data-visualization/reports/site-summary",
      name: "Site Summary",
      component: SitesSummaryTable,
      guard: UserGuard,
      visibilityFilter: UserVisibilityFilter,
    },
    {
      path: "/data-visualization/reports/temperature",
      name: "Temperature",
      component: SitesSummaryTemperatureTable,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

//DOCUMENTS
//sub public files section
const publicFilesRoute = {
  id: "Public Files",
  header: "Documents",
  icon: <Archive />,
  path: "/documents/public-files",
  name: "Public Files",
  component: PublicFiles,
  visibilityFilter: AuthVisibilityFilter,
  guard: AuthGuard,
};

//sub client docs section
const clientDocsRoute = {
  id: "Client Docs",
  icon: <Folder />,
  path: "/documents/client-docs",
  name: "Client Documents",
  component: Blank,
  guard: DeveloperGuard,
  visibilityFilter: DeveloperVisibilityFilter,
};

//sub admin docs section
const adminDocsRoute = {
  id: "Admin Docs",
  icon: <Briefcase />,
  path: "/documents/admin-docs",
  name: "Admin Documents",
  component: Blank,
  guard: DeveloperGuard,
  visibilityFilter: DeveloperVisibilityFilter,
};

//DATA MANAGEMENT
//sub time series section
const ratingCurvesRoute = {
  header: "Data Management",
  id: "Rating Curves",
  icon: <PenTool />,
  path: "/data-management/rating-curves",
  name: "Rating Curves",
  component: RatingCurves,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  mainRoutes,
  flowRoute,
  temperatureRoute,
  flowVsTargetsRoute,
  reportsRoutes,
  publicFilesRoute,
  clientDocsRoute,
  adminDocsRoute,
  ratingCurvesRoute,
  accountRoutes,
];

// Routes using the max content layout
export const dashboardMaxContentLayoutRoutes = [];

// Routes using the Auth layout
export const authLayoutRoutes = [];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  mainRoutes,
  flowRoute,
  temperatureRoute,
  flowVsTargetsRoute,
  reportsRoutes,
  publicFilesRoute,
  clientDocsRoute,
  adminDocsRoute,
  ratingCurvesRoute,
];
